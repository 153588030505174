import { useContext } from 'react'
import { onRemoveFromCart } from '../components/utils/analytics'
import { CartContext } from '../context/CartContext'

const useCart = () => {
	const [cart, setCart] = useContext(CartContext)
	// console.log('cart', cart)

	const addToCart = item => {
		if (item) {
			const exists = cart.lineItems.filter(
				lineItem => lineItem.sku === item.sku
			)
			if (!exists.length) {
				setCart(cart => {
					const lineItems = [
						...cart.lineItems,
						{ ...item, price: Math.round(item.price) },
					]
					return { ...cart, lineItems }
				})
			} else {
				setCart(cart => {
					const lineItems = cart.lineItems.map(lineItem => {
						if (lineItem.sku === item.sku) {
							return {
								...lineItem,
								price: Math.round(item.price),
								quantity: Number(lineItem.quantity) + Number(item.quantity),
							}
						} else {
							return lineItem
						}
					})
					return { ...cart, lineItems }
				})
			}
		}
	}

	const initCheckout = id => {
		// if (cart&&cart.orderId) return '' // Order Already Exists
		// if (cart.orderId === Number(id)) return '' // Order ID is the same
		if (id) {
			setCart(cart => {
				return { ...cart, id }
			})
		}
	}

	const addCoupon = coupon => {
			// const serialMatch = /(^[OZoz]{2})([0-9\s-]+)/.test(coupon)
			const serialMatch = null
			if (serialMatch) {
				return setCart(cart => {
					return {
						...cart,
						coupon: {
							name: '50% OFF MINERALS',
							code: coupon,
							product: ['10-200-1001'],
							discount: ['%', 0.5],
						},
					}
				})
			}
		}

	const clearCart = () =>
		setCart({
			lineItems: [],
			paymentMethod: { id: '', billing_details: {} },
			id: '',
			isPickUp: false,
		})

	const removeFromCart = item => {
		if (item) {
			setCart(cart => {
				const lineItems = cart.lineItems.filter(
					lineItem => lineItem.sku !== item.sku
				)
				cart.coupon = {}
				onRemoveFromCart(item)
				return { ...cart, lineItems }
			})
		}
	}

	const updateQuantity = (index, quantity) => {
		setCart(cart => {
			const lineItems = cart.lineItems.map((lineItem, i) => {
				if (i === index) {
					return { ...lineItem, quantity: Number(quantity) }
				} else {
					return lineItem
				}
			})
			return { ...cart, lineItems }
		})
	}

	const incrementInCart = index => {
		setCart(cart => {
			const lineItems = cart.lineItems.map((lineItem, i) => {
				if (i === index) {
					const { quantity } = lineItem
					return { ...lineItem, quantity: Number(quantity) + 1 }
				} else {
					return lineItem
				}
			})
			return { ...cart, lineItems }
		})
	}
	const decrementInCart = index => {
		if (cart.lineItems[index].quantity >= 2) {
			return setCart(cart => {
				const lineItems = cart.lineItems.map((lineItem, i) => {
					if (i === index) {
						const { quantity } = lineItem
						return { ...lineItem, quantity: Number(quantity - 1) }
					} else {
						return lineItem
					}
				})
				return { ...cart, lineItems }
			})
		} else {
			return removeFromCart(cart.lineItems[index])
		}
	}

	function couponTotal() {
		const hasCoupon = cart && cart.coupon && cart.coupon.code
		const hasProducts = hasCoupon && cart.coupon.product
		var reducer = function add(sumSoFar, item) {
			const matchesCoupon =
				hasProducts &&
				hasProducts
					.filter(product => product === item.sku)
					.map(x => x === item.sku)[0]
			if (matchesCoupon) {
				if (cart.coupon.discount[0] === '%') {
					return (
						sumSoFar +
						Number(item.price * item.quantity * cart.coupon.discount[1])
					)
				}
			}
			return sumSoFar
		}
		const total = cart.lineItems.reduce(reducer, 0)
		return Math.round(total)
	}

	function cartTotal() {
		const discount = couponTotal()
		var reducer = function add(sumSoFar, item) {
			return sumSoFar + Number(item.price * item.quantity)
		}
		const total = cart.lineItems.reduce(reducer, 0)
		return total - discount
	}

	const hasPickUp = cart.lineItems
		.filter(item => item.shippingClass === 'pickup')
		.map(item => item.shippingClass === 'pickup')[0]

	function isPickUp() {
		if (hasPickUp) {
			if (hasPickUp !== cart.isPickUp) setCart({ ...cart, isPickUp: true })
			return true
		}
		return false
	}

	function freightTotal() {
		/** Use reducer to get the max shipping class cost from the order */
		const reducer = function add(prev, item) {
			const getPrice = () => {
				switch (item.shippingClass) {
					case 'free':
						return 0
					case 'small':
						return 990
					case 'standard':
						return 3900
					default:
						return 0
				}
			}
			const price = Number(getPrice())
			return prev > price ? prev : price
		}
		if (hasPickUp) {
			return 0
		}
		return cart.lineItems.reduce(reducer, 0)
	}

	const addBilling = (address, email, name, phone) => {
		setCart(cart => {
			const paymentMethod = cart.paymentMethod.map(details => {
				if (details === 'billing_details') {
					return { ...details, address, email, name, phone }
				} else {
					return details
				}
			})
			return { ...cart, paymentMethod }
		})
	}
	const addPayment = id => {
		setCart(cart => {
			const paymentMethod = cart.paymentMethod.map(details => {
				if (details === 'id') {
					return { ...details, id }
				} else {
					return details
				}
			})
			return { ...cart, paymentMethod }
		})
	}
	const count = () => {
		const quantities =
			cart && cart.lineItems && cart.lineItems.map(item => item.quantity)
		if (Array(quantities) && quantities.length >= 1) {
			return quantities.reduce((acc, cur) => acc + cur)
		}
		return 0
	}
	const subtotal = () => Math.round(cartTotal() / 1.1)
	const tax = () => Math.round((cartTotal() + calculateShipping()) / 11)
	const total = () => Math.round(cartTotal() + calculateShipping())
	const fee = () => Math.round(total() * 0.0175 + 30)
	const orderTotal = () => Math.round(total() - fee())
	// Gives 0 cost for shipping if total is above 850
	const calculateShipping = () => { 
		if (subtotal() / 100 <= 850) {
			return freightTotal() 
		} else {
			return 0
		}
	}
	return {
		count: count(),
		coupon: cart.coupon,
		items: cart.lineItems,
		id: cart.id,
		isPickUp: isPickUp(),
		subtotal: subtotal(),
		freight: calculateShipping(), // Gives 0 cost for shipping if total is above 850
		tax: tax(),
		total: total(),
		fee: fee(),
		orderTotal: orderTotal(),
		couponTotal: couponTotal(),
		initCheckout,
		clearCart,
		addToCart,
		addCoupon,
		updateQuantity,
		removeFromCart,
		incrementInCart,
		decrementInCart,
	}
}

export default useCart
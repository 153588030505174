import React from 'react'
import PropTypes from 'prop-types'
import tw, { css, styled } from 'twin.macro'

const Maytronics = props => {
	const { color, tilde, width, height, style } = props
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 209 50"
			fill={color}
			style={style}
			id="logo"
			alt="Maytronics Australia"
		>
			<path d="M0.211952 15.1231C0.211952 13.6872 0 10.6462 0 9.63077H2.55376C2.60029 11.0667 2.64164 12.6718 2.72436 13.0564H2.8536C3.48968 11.8419 4.457 10.8292 5.64524 10.1338C6.83349 9.43833 8.19483 9.08812 9.57402 9.12308C12.1588 9.12308 14.5523 10.3949 15.4932 13.3487C16.7287 10.4359 19.453 9.12308 22.1774 9.12308C28.3085 9.12308 29.6733 13.2667 29.6733 17.5333V29.1487H27.1298V17.6615C27.1298 14.4923 26.2355 11.4051 22.1877 11.4051C17.4628 11.4051 16.2272 15.3795 16.2272 18.1641V29.1487H13.6683V17.4051C13.6683 13.8154 12.3501 11.4051 9.58436 11.4051C5.7899 11.4051 2.76571 14.0256 2.76571 19.3897V29.1333H0.211952V15.1231Z" />
			<path d="M50.0775 20.9538C50.0156 23.6945 50.1452 26.4361 50.4652 29.159H48.0769C47.9025 28.1115 47.8177 27.0513 47.8236 25.9897H47.7357C46.2882 28.4872 44.1584 29.6667 40.8395 29.6667C37.5206 29.6667 33.8968 28.0103 33.8968 23.8718C33.8968 17.9128 40.9222 17.3231 44.7994 17.3231H47.7822V16.1385C47.7822 12.759 45.3939 11.4051 42.2456 11.4051C40.0371 11.3715 37.8953 12.1559 36.2386 13.6051L34.7498 11.8718C36.6211 10.0974 39.6867 9.12308 42.2456 9.12308C47.012 9.12308 50.0775 11.2359 50.0775 16.1795V20.9538ZM44.9751 19.3487C40.7154 19.3487 36.4557 20.4051 36.4557 23.7436C36.4557 26.1487 38.7975 27.3744 40.9274 27.3744C46.3813 27.3744 47.7874 23.9128 47.7874 20.6615V19.3487H44.9751Z" />
			<path d="M61.5746 35.1897C60.5975 37.6872 58.9898 39.2923 56.1672 39.2923C55.3165 39.273 54.4719 39.1437 53.6548 38.9077L53.9133 36.5436C54.6531 36.8033 55.4255 36.9604 56.2086 37.0103C58.0851 37.0103 58.8916 35.2769 59.4913 33.7538L61.2799 29.2308L53.2723 9.63077H56.1259L62.6395 26.0667L68.9877 9.65641H71.7121L61.5746 35.1897Z" />
			<path d="M86.4454 11.9128H80.8261V24.6154C80.8261 26.3487 81.86 27.359 83.5504 27.359C84.5506 27.3262 85.5343 27.0979 86.4454 26.6872L86.6573 28.9641C85.5919 29.3548 84.4729 29.5831 83.3385 29.641C79.6732 29.641 78.2671 27.4051 78.2671 24.6154V11.9128H74.0126V9.63077H78.2671V3.98975H80.8261V9.63077H86.4454V11.9128Z" />
			<path d="M91.8527 15.1231C91.8527 13.6872 91.6407 10.6462 91.6407 9.63077H94.1945C94.2359 11.0667 94.2824 12.6718 94.3651 13.0564C95.6368 10.7692 97.7046 9.12308 100.625 9.12308C101.131 9.13182 101.633 9.20419 102.119 9.33846L101.84 11.8308C101.524 11.7222 101.193 11.665 100.858 11.6615C96.4329 11.6615 94.4271 14.7026 94.4271 18.5026V29.1487H91.8734L91.8527 15.1231Z" />
			<path d="M145.213 15.1231C145.213 13.6872 144.996 10.6462 144.996 9.63077H147.555C147.596 11.0667 147.637 12.6718 147.725 13.0564H147.849C148.485 11.8419 149.453 10.8292 150.641 10.1338C151.829 9.43833 153.191 9.08812 154.57 9.12308C160.701 9.12308 162.066 13.2667 162.066 17.5333V29.1487H159.512V17.6615C159.512 14.4923 158.618 11.4051 154.57 11.4051C150.78 11.4051 147.756 14.0256 147.756 19.3897V29.1333H145.213V15.1231Z" />
			<path d="M168.036 6.94944e-06C168.416 6.94944e-06 168.787 0.11159 169.102 0.320647C169.417 0.529703 169.663 0.826843 169.808 1.17449C169.953 1.52214 169.991 1.90468 169.917 2.27374C169.843 2.6428 169.661 2.98181 169.393 3.24789C169.124 3.51397 168.783 3.69517 168.411 3.76858C168.039 3.84199 167.653 3.80431 167.302 3.66031C166.952 3.51631 166.652 3.27245 166.442 2.95958C166.231 2.6467 166.119 2.27886 166.119 1.90257C166.118 1.65253 166.167 1.40483 166.263 1.17369C166.359 0.942556 166.501 0.732551 166.679 0.555746C166.857 0.378942 167.069 0.238826 167.302 0.143453C167.535 0.0480796 167.784 -0.000669748 168.036 6.94944e-06ZM166.76 9.63078H169.313V29.1487H166.76V9.63078Z" />
			<path d="M191.33 26.2769C190.462 27.3482 189.359 28.2093 188.105 28.7948C186.851 29.3804 185.479 29.675 184.093 29.6564C178.05 29.6564 173.754 25.3487 173.754 19.4C173.754 13.4513 178.06 9.14359 184.093 9.14359C185.488 9.14774 186.865 9.45205 188.13 10.0355C189.394 10.619 190.516 11.4677 191.418 12.5231L189.376 14.0615C187.888 12.4564 186.14 11.441 184.093 11.441C179.28 11.441 176.303 15.0308 176.303 19.4256C176.303 23.8205 179.28 27.4103 184.093 27.4103C185.122 27.3828 186.131 27.1255 187.046 26.6576C187.961 26.1897 188.757 25.5232 189.376 24.7077L191.33 26.2769Z" />
			<path d="M196.226 24.1641C197.291 26.2154 199.633 27.3744 201.913 27.3744C204.125 27.3744 206.426 26.0256 206.426 23.7846C206.426 18.4205 194.758 22.1795 194.758 14.7436C194.758 10.9436 198.247 9.10257 201.478 9.10257C204.709 9.10257 207.165 10.2872 208.421 12.7795L206.121 14.1333C205.713 13.265 205.051 12.5385 204.221 12.0483C203.391 11.5581 202.432 11.3268 201.468 11.3846C199.638 11.3846 197.296 12.4821 197.296 14.5128C197.296 19.7949 208.964 15.6974 208.964 23.5949C208.964 27.6103 205.604 29.6359 201.856 29.6359C198.49 29.6359 195.466 28.3692 193.931 25.6667L196.226 24.1641Z" />
			<path d="M121.355 8.93332C110.644 8.96922 101.964 18.3179 101.882 29.8769C102.926 30.23 103.948 30.6462 104.942 31.1231C106.069 31.654 107.167 32.2446 108.23 32.8923C108.055 31.9535 107.967 31.0008 107.966 30.0461C107.966 22.0461 113.978 15.5282 121.376 15.4923C128.774 15.4564 134.765 22.0513 134.765 30.0461C134.734 31.3194 134.615 32.5891 134.408 33.8461C136.904 33.1956 139.148 31.8228 140.855 29.9025C140.746 18.3179 132.067 8.96922 121.355 8.93332Z" />
			<path
				d="M139.382 34.641C137.004 36.7988 134.207 38.4522 131.163 39.4998C128.118 40.5474 124.89 40.9672 121.676 40.7333C117.831 40.4478 114.09 39.3572 110.701 37.5333C109.941 37.1128 108.917 36.5436 108.4 36.2256C107.883 35.9077 107.367 35.5487 106.85 35.2564C105.834 34.6458 104.787 34.0895 103.712 33.5897C99.4835 31.604 94.7765 30.8415 90.1312 31.3897C84.4461 32.1744 79.0183 34.2429 74.2659 37.4359C79.0116 35.5092 84.1443 34.705 89.2576 35.0872C93.4487 35.4801 97.4472 37.0213 100.806 39.5385C101.671 40.1789 102.501 40.8636 103.293 41.5897C103.696 41.9385 104.079 42.3538 104.472 42.7231C104.864 43.0923 105.686 43.7897 106.281 44.3026C108.949 46.5333 112.056 48.1873 115.405 49.159C118.164 49.9686 121.061 50.2042 123.916 49.8513C126.77 49.4983 129.521 48.5643 131.994 47.1077C134.991 45.349 137.482 42.8577 139.232 39.8718C141.775 35.3385 141.258 32.6462 141.299 32.6923C140.694 33.3739 140.054 34.0243 139.382 34.641Z"
				fill={tilde}
			/>
		</svg>
	)
}

Maytronics.propTypes = {
	color: PropTypes.string,
	tilde: PropTypes.string,
	width: PropTypes.string,
	height: PropTypes.string,
	style: PropTypes.any,
}
Maytronics.defaultProps = {
	color: '#353E47',
	tilde: '#58C8DF',
	width: '150',
	height: '36',
}

export default Maytronics
